export const PairFieldsHYDRA = `
    pairAddress
    txCount
    token0 {
      tokenAddress
      symbol
      name
      totalLiquidity
      derivedHYDRA
    }
    token1 {
      tokenAddress
      symbol
      name
      totalLiquidity
      derivedHYDRA
    }
    reserve0
    reserve1
    reserveUSD
    totalSupply
    trackedReserveHYDRA
    reserveHYDRA
    volumeUSD
    untrackedVolumeUSD
    token0Price
    token1Price
    createdAtTimestamp
`;

export let WBTCAddress = "27a459ac47204f1e7fc5c48ffeafa990af244e13";
export let USDTAddress = "932917c89717c809f67ebb421e24fd04d7a96d33";
export let USDCAddress = "e4b8164dccf3489f66124cace1570dc35b58fc90";
export let DAIAddress = "abc2cd00700e06922bcf30fe0ad648507113cc56";
export let ETHAddress = "ab9e970de9ba9ec2c64a7f7f911da44b750ecf6a";
export let BUSDAddress = "0c404645b9e36b6cf9bcaf2747e472334b268e9d";
export let HYDRAAddress = "6d9115a21863ce31b44cd231e4c4ccc87566222f";
