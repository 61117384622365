import React, { useEffect, useState } from "react";
import Project from "./Project";
import rezchainLogo from "../../assets/images/partner/d_partner_rezchain.png";
import evedoLogo from "../../assets/images/projects/evedo-logo.svg";

import hydraLogo from "../../assets/images/logo.png";
import lydraLogo from "../../assets/images/projects/Lydra_logo.jpg";
import goMeatLogo from "../../assets/images/projects/gomeat.png";

import lockTripLogo from "../../assets/images/projects/locktrip-logo.svg";

import ChangeXLogo from "../../assets/images/projects/Changex-logo.png";

import useFetch from "use-http";
import {
  formatDollarAmount,
  formatHydra,
  formattedNum,
  getSumByKey,
} from "../../utils";
import {
  BUSDAddress,
  DAIAddress,
  ETHAddress,
  HYDRAAddress,
  PairFieldsHYDRA,
  USDCAddress,
  USDTAddress,
  WBTCAddress,
} from "./Constants";

const Projects = () => {
  const locInfoURL = `https://explorer.hydrachain.org/api/contract/4ab26aaa1803daa638910d71075c06386e391147`;
  const changeXInfoURL = `https://explorer.hydrachain.org/api/contract/bd3c617d271b3467bd9b83dda73c9288de2fb0c9`;
  const evedoPriceURL = `https://api.coingecko.com/api/v3/simple/price?ids=evedo&vs_currencies=usd`;
  const locPriceURL =
    "https://api.coingecko.com/api/v3/simple/price?ids=lockchain&vs_currencies=usd";
  const changeXPriceURL =
    "https://api.coingecko.com/api/v3/simple/price?ids=changeX&vs_currencies=usd";

  const goMeatPriceURL =
    "https://api.coingecko.com/api/v3/simple/price?ids=goMeat&vs_currencies=usd";
  const stakeCountChangeXUrl =
    "https://hydra-dex-backend.changex.io/api/staking/active-stakers?contract=72c9a79baa83e698bf1dbf44d26e5bdca2d2bab1";

  const goMeatCirculatingSupplyUrl =
    "https://telegrambot.gomeat.io/api/gomtTokenInfo?q=circulating";
  const goMeatTransactionUrl =
    "https://explorer.hydrachain.org/api/contract/7b8dd012ec9e1f11e008376ef21d29ba4cc78e24/";

  const changeXCirculatingUrl =
    "https://hydra-dex-backend.changex.io/api/supply/circulating";

  const {
    loading: loadingLocInfo,
    error: errorLocInfo,
    data: locInfo,
  } = useFetch(locInfoURL, null, []);

  const {
    loading: loadingChangeXInfo,
    error: errorChangeXInfo,
    data: changeXInfo,
  } = useFetch(changeXInfoURL, null, []);
  const {
    loading: loadingChangeXPrice,
    error: errorChangeXPrice,
    data: changeXPrice,
  } = useFetch(changeXPriceURL, null, []);

  const {
    loading: loadingGoMeatPrice,
    error: errorGoMeatPrice,
    data: goMeatPrice,
  } = useFetch(goMeatPriceURL, null, []);
  const {
    loading: loadingChangeXStakeCount,
    error: errorChangeXStakeCount,
    data: changeXStakeCount,
  } = useFetch(stakeCountChangeXUrl, null, []);

  const {
    loading: loadingEvedoPrice,
    error: errorEvedoPrice,
    data: evedoPrice,
  } = useFetch(evedoPriceURL, null, []);
  const {
    loading: loadingLocPrice,
    error: errorLocPrice,
    data: locPrice,
  } = useFetch(locPriceURL, null, []);

  const {
    loading: loadingChangeXCap,
    error: errorChangeXCap,
    data: changeXCap,
  } = useFetch(changeXCirculatingUrl, null, []);

  const {
    loading: loadingGoMeatCirculatingSupply,
    error: errorGoMeatCirculatingSupply,
    data: goMeatCirculatingSupply,
  } = useFetch(goMeatCirculatingSupplyUrl, null, []);
  const [goMeatTransactionCount, setGoMeatTransactionCount] = useState();

  const [goMeatHoldersCount, setGoMeatHoldersCount] = useState();
  const [hydraBridgeInfo, setHydraBridgeInfo] = useState();

  const [hydraBridgeTvlValue, setHydraBridgeTvlValue] = useState();

  const [
    hydraBridgeTransactionCount,
    setHydraBridgeTransactionCount,
  ] = useState();

  const { qrc20, transactionCount: transactionCountLOC } = locInfo || {};

  const { qrc20: qrc20ChangeX, transactionCount: transactionCountChangeX } =
    changeXInfo || {};

  const { loading: loadingInfo, get: fetchGoMeatTransactionCount } = useFetch(
    goMeatTransactionUrl
  );

  const { get: fetchHydraBridegeInfo } = useFetch(
    `https://explorer.hydrachain.org/api/contract/2b58d716cefec778900e26a610274130cea5cfd5/`
  );

  const [hydraInfo, setHydraInfo] = useState();

  useEffect(async () => {
    getHydraInfo();
    getGoMeatTransactionInfo();
    getHydraBridgeInfo();
    getTvlInfo();
  }, []);

  const getHydraBridgeInfo = async () => {
    const response = await fetchHydraBridegeInfo();
    setHydraBridgeInfo(response);
  };

  const compareTokens = (token0, token1, address1, address2) => {
    let array = [address1, address2];
    if (array.includes(token0) && array.includes(token1)) {
      return true;
    }
  };

  const getTvlInfo = async () => {
    let pairs = await post({
      query: `{
        pairs( orderBy: "trackedReserveHYDRA", orderDirection: "desc") {${PairFieldsHYDRA}}}
      `,
    });
    pairs = pairs.data.pairs.map((pair) => {
      return {
        ...pair,
        formattedLiquidity: pair?.trackedReserveUSD
          ? pair?.trackedReserveUSD
          : pair?.reserveUSD,
      };
    });
    let USDC_USDT = 0;
    let ETH_HYDRA = 0;
    let DAI_HYDRA = 0;
    let WBTC_HYDRA = 0;
    let USDC_DAI = 0;
    let USDC_BUSD = 0;

    pairs.map((pair) => {
      const token0 = pair?.token0?.tokenAddress;
      const token1 = pair?.token1?.tokenAddress;
      if (compareTokens(token0, token1, USDTAddress, USDCAddress)) {
        USDC_USDT = pair?.formattedLiquidity;
      }
      if (compareTokens(token0, token1, ETHAddress, HYDRAAddress)) {
        ETH_HYDRA = pair?.formattedLiquidity;
      }
      if (compareTokens(token0, token1, DAIAddress, HYDRAAddress)) {
        DAI_HYDRA = pair?.formattedLiquidity;
      }
      if (compareTokens(token0, token1, WBTCAddress, HYDRAAddress)) {
        WBTC_HYDRA = pair?.formattedLiquidity;
      }
      if (compareTokens(token0, token1, DAIAddress, USDCAddress)) {
        USDC_DAI = pair?.formattedLiquidity;
      }
      if (compareTokens(token0, token1, BUSDAddress, USDCAddress)) {
        USDC_BUSD = pair?.formattedLiquidity;
      }
    });

    let totalTvlValue =
      (USDC_USDT ? USDC_USDT / 2 : 0) +
      (ETH_HYDRA ? ETH_HYDRA / 2 : 0) +
      (DAI_HYDRA ? DAI_HYDRA / 2 : 0) +
      (WBTC_HYDRA ? WBTC_HYDRA / 2 : 0) +
      (USDC_DAI ? USDC_DAI / 2 : 0) +
      (USDC_BUSD ? USDC_BUSD / 2 : 0);
    setHydraBridgeTvlValue(totalTvlValue);
  };

  useEffect(() => {
    setHydraBridgeTransactionCount(
      6345 + 7 * hydraBridgeInfo?.transactionCount
    );
  }, [hydraBridgeInfo]);

  const getGoMeatTransactionInfo = async () => {
    const response = await fetchGoMeatTransactionCount();
    setGoMeatTransactionCount(response?.transactionCount);
    setGoMeatHoldersCount(response?.qrc20?.holders);
  };

  const { post } = useFetch("https://info.hydradex.org/graphql");

  const getHydraInfo = async () => {
    let response = await post({
      query: `
        {
          hydraswapFactories{id,totalVolumeUSD, totalLiquidityUSD, totalLiquidityHYDRA,txCount}
        }`,
    });
    setHydraInfo(response.data.hydraswapFactories[0]);
  };

  const projects = [
    {
      name: "LockTrip",
      logo: lockTripLogo || "https://locktrip.com/images/locktrip_logo.svg",
      status: "Operational",
      description:
        "LockTrip is a blockchain-based travel marketplace that allows users to save up to 60% on their bookings by cutting out middlemen. Every booking burns LOC proportionally to its booking value, continuously reducing its total supply.",
      marketCap:
        "US$ " + ((locPrice?.lockchain?.usd || 0) * 14).toFixed(2) + "M",
      transactionsCount: transactionCountLOC || 0,
      holdersCount: qrc20?.holders || 0,
      website: { link: "https://locktrip.com", title: "LockTrip.com" },
      community: "https://t.me/LockTrip",
      loading: loadingLocInfo || loadingLocPrice,
    },
    {
      name: "ChangeX",
      logo: ChangeXLogo,
      status: "Launched Recently",
      description:
        "Changex is a non-custodial mobile crypto wallet with a focus on PoS assets and DeFi-based passive income opportunities. A hybrid DeFi/CeFi application designing a unique leveraged staking product with a Visa card to spend staking income without interrupting staking and integrated bank account for a quick & easy fiat access. Changex aims to simplify the crypto journey while maintaing the ownership in the user's hands.",
      marketCap:
        "US$ " +
        ((changeXCap * changeXPrice?.changex?.usd) / 1000000 || 0).toFixed(2) +
        "M",
      transactionsCount: transactionCountChangeX || 0,
      holdersCount:
        qrc20ChangeX?.holders + changeXStakeCount?.activeStakers || 0,
      loading:
        loadingChangeXInfo || loadingChangeXPrice || loadingChangeXStakeCount,
    },
    {
      name: "Hydra DEX",
      logo: hydraLogo,
      status: "Operational",
      description:
        "Hydra DEX is the native decentralized exchange of Hydra chain, where various assets can be traded against each other through the setup of liquidity pools. Yielding trading fees to liquidity providers.",
      marketCapTitle: "Total Liquidity in Pools",
      marketCap:
        "US$ " +
        ((hydraInfo?.totalLiquidityUSD || 0) / 1000000).toFixed(2) +
        "M",

      transactionsCount: hydraInfo?.txCount,
      holdersCountTitle: "Total volume ",
      holdersCount:
        "US$ " + ((hydraInfo?.totalVolumeUSD || 0) / 1000000).toFixed(2) + "M",

      website: { link: "https://hydradex.org/", title: "hydradex.org" },
    },
    {
      name: "GoMeat",
      logo: goMeatLogo,
      status: "Operational",
      description:
        "GoMeat is a digital marketplace connecting consumers with specialty (halal & kosher) meat, grocery and restaurants. Providing lucrative discounts and same-day delivery since 2019 , GoMeat is empowering local ethnic stores and providing convenience to customers . Enabling blockchain based specialty certifications to bring transparency and traceability to the industry.",
      marketCap:
        "US$ " +
        (
          (goMeatCirculatingSupply * goMeatPrice?.gomeat?.usd || 0) / 1000000
        ).toFixed(2) +
        "M",
      transactionsCount: goMeatTransactionCount,
      holdersCount: goMeatHoldersCount,
      website: { link: "https://gomeat.io", title: "GoMeat.io" },
      community: "https://t.me/GoMeatToken",
      loading: loadingGoMeatCirculatingSupply || loadingGoMeatPrice,
    },
    {
      name: "Hydra Bridge",
      logo: hydraLogo,
      status: "Operational",
      description:
        "Hydra Bridge's main purpose is to enable transfer of assets between the Ethereum and Hydra chains. Users holding assets on Ethereum can exchange them to corresponding assets on Hydra (1:1). Hydra Bridge also allows redemption of the exchanged assets back to the user's Ethereum account at any time.",
      marketCapTitle: "Total Value Locked",
      marketCap:
        "US$ " + ((hydraBridgeTvlValue || 0) / 1000000).toFixed(2) + "M",

      transactionsCount: hydraBridgeTransactionCount || 0,
      holdersCount: "Pending",
      website: {
        link: "https://bridge.hydrachain.org/eth",
        title: "bridge.hydrachain.org",
      },
    },
    {
      name: "Evedo",
      logo: evedoLogo,
      status: "Migration in Progress",
      description:
        "Evedo is a blockchain based platform, consisting of B2B & B2C Marketplaces. It unifies all businesses and participants involved in organizing events.",
      marketCap:
        "US$ " + ((evedoPrice?.evedo?.usd || 0) * 14.4).toFixed(2) + "M",
      transactionsCount: "Awaiting Migration",
      holdersCount: "1,600+",
      website: { link: "https://www.evedo.co", title: "evedo.co" },
      community: "https://t.me/evedoco",
      loading: loadingEvedoPrice,
    },

    {
      name: "Lydra",
      logo: lydraLogo,
      status: "In Development",
      description:
        "LYDRA is designed to unlock a world on its own and transform the economy through a new concept that we call Liquid Staking. While HYDRA created a major economy with its own ecosystem, LYDRA will be enabling a second core-economy in parallel and as an asset introduce unique synergies to the existing capabilities of HYDRA.",
      marketCap: "Pending",
      transactionsCount: "Pending",
      holdersCount: "Pending",
      website: {
        link:
          "https://docs.google.com/document/d/e/2PACX-1vReUzdGzkc3Plm-VbLv2TGWeJ81dMmraKczCaUVRBDix3s4PROipcJ2aDQ1U_bZruU_OplXVQqCCPbe/pub",
        title: "View WhitePaper",
      },
    },
    {
      name: "RezChain",
      logo: rezchainLogo,
      status: "Exploration stage",
      description:
        "Rezchain is the hotel distribution industry’s first functioning blockchain enabled technology platform allowing companies to share booking data “on the chain” in order to address mismatched information in real time.",
      marketCap: "Pending",
      transactionsCount: "Pending",
      holdersCount: "Pending",
      website: { link: "https://rezchain.com/", title: "rezchain.com" },
    },
  ];
  return (
    <section className="about-area ptb-100 bg-f1f8fb">
      <div className="container">
        {projects.map((project) => (
          <Project project={project}></Project>
        ))}
      </div>
    </section>
  );
};

export default Projects;
