import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import ProjectsIntro from "../components/Projects/ProjectsIntro";
import Partner from "../components/Projects/Partner";
import Projects from "../components/Projects/Projects";
import { Helmet } from "react-helmet";

const ProjectsPage = ({ location }) => {
  return (
    <Layout>
      <Helmet>
        <title>Projects | HydraChain</title>
        <link rel="canonical" href={`https://hydrachain.org/${location.pathname}`} />
      </Helmet>
      <Navbar />
      <PageBanner pageTitle="Projects" homePageText="Home" homePageUrl="/" activePageText="Projects" />
      <ProjectsIntro />
      <Projects />
      <Partner />
      <Footer />
    </Layout>
  );
};

export default ProjectsPage;
